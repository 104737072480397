/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Segmented, Spin, type TimeRangePickerProps } from 'antd';
import { BarChart } from 'components/charts/BarChart';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    type BarChartData,
    type BarChartProps,
    ChartInterval,
    ChartType,
    type LineChartData,
    type LineChartProps,
    RangePicker,
    dateFormat,
    getDailyBarData,
    getDailyLineData,
    getMonthlyBarData,
    getMonthlyLineData,
    getWeeklyBarData,
    getWeeklyLineData,
} from 'utils';

import { LineChart } from '../charts/LineChart';
import type { StatsQuery } from '.graphclient';

export const ActiveAccounts = ({
    data,
    isLoading,
    defaultChartInterval = ChartInterval.Weekly,
    isPreview = false,
}: {
    data: StatsQuery;
    isLoading: boolean;
    defaultChartInterval?: ChartInterval;
    isPreview?: boolean;
}): $MixedElement => {
    const [dateRange, setDateRange] = useState<[number, number]>([0, 0]);
    const [chartInterval, setChartInterval] =
        useState<ChartInterval>(defaultChartInterval);
    const [chartType, setChartType] = useState<ChartType>(ChartType.Line);

    const onDateChange: TimeRangePickerProps['onChange'] = (date) => {
        if (date && date[0] && date[1]) {
            setDateRange([date[0].unix(), date[1].unix()]);
            setChartInterval(ChartInterval.Daily);
        }
    };

    const chartData = useMemo(() => {
        switch (chartType) {
            case ChartType.Line: {
                const dailyData = getDailyLineData(
                    data,
                    'activeAccounts',
                    dateRange,
                );
                const weeklyData = getWeeklyLineData(data, 'activeAccounts');
                const monthlyData = getMonthlyLineData(data, 'activeAccounts');

                return {
                    [ChartInterval.Daily]: [
                        {
                            id: 'daily active accounts',
                            data: dailyData,
                        },
                    ],
                    [ChartInterval.Weekly]: [
                        {
                            id: 'weekly active accounts',
                            data: weeklyData,
                        },
                    ],
                    [ChartInterval.Monthly]: [
                        {
                            id: 'monthly active accounts',
                            data: monthlyData,
                        },
                    ],
                    [ChartInterval.Cumulative]: [],
                };
            }
            case ChartType.Bar: {
                const dailyData = getDailyBarData(
                    data,
                    'activeAccounts',
                    dateRange,
                );
                const weeklyData = getWeeklyBarData(data, 'activeAccounts');
                const monthlyData = getMonthlyBarData(data, 'activeAccounts');

                return {
                    [ChartInterval.Daily]: dailyData,
                    [ChartInterval.Weekly]: weeklyData,
                    [ChartInterval.Monthly]: monthlyData,
                    [ChartInterval.Cumulative]: [],
                };
            }
        }
    }, [data, chartType, dateRange]);

    const lineProps: LineChartProps = {
        axisBottomLegend: 'Date',
        axisLeftLegend: 'Count',
        axisLeftTickValues: 20,
        axisLeftFormat: undefined,
        yFormat: undefined,
        margin: isPreview
            ? { top: 20, right: 50, bottom: 50, left: 50 }
            : { top: 40, right: 200, bottom: 100, left: 100 },
    };

    const barProps: BarChartProps<'count'> = {
        keys: ['count'],
        indexBy: 'date',
        axisBottomLegend: 'Date',
        axisLeftLegend: 'Count',
        enableTotals: false,
    };

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }
    return (
        <div className="w-[100%] min-h-[40vh] max-h-[100vh]">
            {!isPreview && (
                <>
                    <Segmented
                        options={[
                            ChartInterval.Daily,
                            ChartInterval.Weekly,
                            ChartInterval.Monthly,
                        ]}
                        value={chartInterval}
                        defaultValue={defaultChartInterval}
                        onChange={(value): void => {
                            if (
                                value === ChartInterval.Daily &&
                                dateRange[0] === 0
                            ) {
                                setDateRange([
                                    dayjs().subtract(1, 'week').unix() - 80000,
                                    dayjs().unix(),
                                ]);
                            }
                            setChartInterval(value as ChartInterval);
                        }}
                        style={{ marginBottom: 12, marginLeft: 12 }}
                    />
                    <Segmented
                        options={[ChartType.Line, ChartType.Bar]}
                        onChange={(value): void => {
                            setChartType(value as ChartType);
                        }}
                        style={{ marginBottom: 12, marginLeft: 24 }}
                    />
                    <RangePicker
                        onChange={onDateChange}
                        value={
                            dateRange[0] === 0
                                ? undefined
                                : [
                                      dayjs.unix(dateRange[0]),
                                      dayjs.unix(dateRange[1]),
                                  ]
                        }
                        minDate={dayjs('2024-01-21', dateFormat)}
                        maxDate={dayjs()}
                        style={{ marginLeft: 24 }}
                    />
                </>
            )}
            {chartType === ChartType.Line ? (
                <LineChart
                    data={chartData[chartInterval] as LineChartData}
                    props={lineProps}
                />
            ) : (
                <BarChart
                    data={
                        chartData[chartInterval] as Array<BarChartData<'count'>>
                    }
                    props={barProps}
                />
            )}
        </div>
    );
};
