import {
    Account,
    Dashboard,
    DashboardV2,
    DevtoolsBase64,
    DevtoolsEnvParser,
    DevtoolsIcons,
    Gatekeeper,
    GatekeeperDetails,
    GenerateWLCode,
    Home,
    Locales,
    Notifications,
    Raffle,
    Waitlist,
} from 'pages';
import { Permission } from 'utils';

/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export const Paths = {
    AUTH: '/auth',
    HOME: '/',
    DASHBOARD: '/dashboard',
    DASHBOARDV2: '/dashboardv2',
    NOTIFICATIONS: '/notifications',
    LOCALES: '/locales',
    ACCOUNT: '/account',
    GRAPH: '/graph',
    USERS: '/users',
    WAITLIST: '/waitlist',
    DEVTOOLS: '/devtools',
    DEVTOOLS_ENVPARSER: '/devtools/envparser',
    DEVTOOLS_ICONS: '/devtools/icons',
    DEVTOOLS_BASE64: '/devtools/base64',
    GENERATEWLCODE: '/waitlist/generate',
    RAFFLES: '/raffles',
    GK: '/gk',
    GK_DETAILS: '/gk/:name',
};

export const authRoutes = [
    {
        path: Paths.HOME,
        element: <Home />,
    },
    {
        path: Paths.DASHBOARD,
        element: <Dashboard />,
        permission: Permission.DASHBOARD_ALL,
    },
    {
        path: Paths.DASHBOARDV2,
        element: <DashboardV2 />,
        permission: Permission.DASHBOARD_V2_ALL,
    },
    {
        path: Paths.NOTIFICATIONS,
        element: <Notifications />,
        permission: Permission.PUSH_NOTIFICATIONS_ALL,
    },
    {
        path: Paths.WAITLIST,
        element: <Waitlist />,
        permission: Permission.WAITLIST_READ,
    },
    {
        path: Paths.LOCALES,
        element: <Locales />,
        permission: Permission.LOCALES_ALL,
    },
    {
        path: Paths.ACCOUNT,
        element: <Account />,
    },
    {
        path: Paths.DEVTOOLS_ENVPARSER,
        element: <DevtoolsEnvParser />,
        permission: Permission.DEV,
    },
    {
        path: Paths.GENERATEWLCODE,
        element: <GenerateWLCode />,
        permission: Permission.WAITLIST_CODE_ALL,
    },
    {
        path: Paths.DEVTOOLS_ICONS,
        element: <DevtoolsIcons />,
        permission: Permission.DEV,
    },
    {
        path: Paths.DEVTOOLS_BASE64,
        element: <DevtoolsBase64 />,
        permission: Permission.DEV,
    },
    {
        path: Paths.RAFFLES,
        element: <Raffle />,
        permission: Permission.RAFFLES_ALL,
    },
    {
        path: Paths.GK,
        element: <Gatekeeper />,
        permission: Permission.GATEKEEPER_ALL,
    },
    {
        path: Paths.GK_DETAILS,
        element: <GatekeeperDetails />,
        permission: Permission.GATEKEEPER_ALL,
    },
];

export const unAuthRoutes = [
    {
        path: '*',
        element: <></>,
    },
];
