import { DatePicker } from 'antd';
import type { UserDataDto } from 'api/types';
import { type IUser } from 'utils';

/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
export const FEE_PCT = 4 / 995;
export const GRAPH_ETH_ADDRESS = '0x000000000000000000000000000000000000800a';
export const ETH_ADDRESS = '0x0000000000000000000000000000000000000000';
export const skipList = ['0x2fc617e933a52713247ce25730f6695920b3befe'];
export const PAGE_SIZE = 200;
export const DEFAULT_SORT_BY = 'totalPortfolio' as keyof IUser;
export const DEFAULT_CHECKED: Array<keyof IUser> = [
    'username',
    'idlePortfolio',
    'swapRevenue',
    'txCount',
    'earnPositions',
    'totalPortfolio',
];
export const { RangePicker } = DatePicker;
export const dateFormat = 'YYYY-MM-DD';
export const defaultUserData: UserDataDto = {
    email: null,
    username: null,
    countryCode: null,
    device: null,
    referrerAddress: null,
    creationDate: '',
    referredWallets: [],
    campaigns: [],
    txDistribution: {
        swap: 0,
        invest: 0,
        peanut: 0,
        transfer: 0,
    },
    depositData: {},
};

// Group columns for better organization
export const columnGroups = [
    {
        title: 'User Info',
        children: [
            'username',
            'email',
            'key',
            'referrer',
            'countryCode',
            'device',
        ],
    },
    {
        title: 'Portfolio',
        children: [
            'totalPortfolio',
            'idlePortfolio',
            'earnPositions',
            'deposit',
        ],
    },
    {
        title: 'Revenue',
        children: ['swapRevenue', 'realizedGain', 'referralGain'],
    },
    {
        title: 'Activity',
        children: [
            'txCount',
            'activeDays',
            'activeWeeks',
            'activeMonths',
            'paymentLinks',
            'swap',
            'transfer',
            'earn',
        ],
    },
    {
        title: 'Other',
        children: [
            'hasBackup',
            'campaignStatus',
            'refCount',
            'creationDate',
            'upgraded',
        ],
    },
];
