/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
    AppstoreOutlined,
    CodeOutlined,
    FileTextOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    LineChartOutlined,
    MailOutlined,
    MenuOutlined,
    MoneyCollectOutlined,
    NotificationOutlined,
    PlusCircleOutlined,
    SecurityScanOutlined,
    SwitcherOutlined,
    TranslationOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { Col, Menu } from 'antd';
import type { MenuProps } from 'antd';
import LogoBlack from 'assets/logo-black.png';
import { Paths } from 'const';
import { useClaveNavigation } from 'hooks';
import { useLocation } from 'react-router-dom';
import {
    useIsSidebarOpenNullSafe,
    useSetIsSidebarOpen,
} from 'store/SidebarStore';
import type { $MixedElement } from 'types';
import { Permission, permissions } from 'utils';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: Array<MenuItem>,
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

export const Sidebar = (): $MixedElement => {
    const navigate = useClaveNavigation();
    const location = useLocation();
    const setIsSideBarOpen = useSetIsSidebarOpen();
    const isSidebarOpen = useIsSidebarOpenNullSafe();

    const _getItem = (
        permission: Permission,
        label: React.ReactNode,
        key: React.Key,
        icon?: React.ReactNode,
        children?: Array<MenuItem>,
        type?: 'group',
    ): MenuItem => {
        if (!permissions.hasPermission(permission)) {
            return null;
        }
        return getItem(label, key, icon, children, type);
    };

    return (
        <Col>
            <Menu
                onClick={(e): void => {
                    if (e.key === 'logo') {
                        setIsSideBarOpen(!isSidebarOpen);
                        return;
                    }
                    navigate(e.key);
                }}
                className={`${
                    isSidebarOpen ? 'w-[320px]' : 'w-[60px]'
                } h-screen`}
                selectedKeys={[location.pathname]}
                inlineCollapsed={false}
                mode="inline"
                items={[
                    getItem(
                        '',
                        'logo',
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            {isSidebarOpen ? (
                                <img
                                    src={LogoBlack}
                                    className="p-4 pl-0 w-[80px]"
                                />
                            ) : null}
                            <MenuOutlined size={42} />
                        </div>,
                    ),
                    getItem('Home', Paths.HOME, <HomeOutlined />),
                    _getItem(
                        Permission.DASHBOARD_ALL,
                        'Dashboard',
                        Paths.DASHBOARD,
                        <AppstoreOutlined />,
                    ),
                    _getItem(
                        Permission.DASHBOARD_V2_ALL,
                        'Dashboard V2',
                        Paths.DASHBOARDV2,
                        <LineChartOutlined />,
                    ),
                    _getItem(
                        Permission.WAITLIST_READ,
                        'Waitlist',
                        Paths.WAITLIST,
                        <MailOutlined />,
                    ),
                    _getItem(
                        Permission.PUSH_NOTIFICATIONS_ALL,
                        'Notifications',
                        Paths.NOTIFICATIONS,
                        <NotificationOutlined />,
                    ),
                    _getItem(
                        Permission.WAITLIST_CODE_ALL,
                        'Waitlist Codes',
                        Paths.GENERATEWLCODE,
                        <PlusCircleOutlined />,
                    ),
                    _getItem(
                        Permission.RAFFLES_ALL,
                        'Raffles',
                        Paths.RAFFLES,
                        <MoneyCollectOutlined />,
                    ),
                    _getItem(
                        Permission.GATEKEEPER_ALL,
                        'Gatekeeper',
                        Paths.GK,
                        <SecurityScanOutlined />,
                    ),
                    _getItem(
                        Permission.LOCALES_ALL,
                        'Locales',
                        Paths.LOCALES,
                        <TranslationOutlined />,
                    ),
                    getItem('Account', Paths.ACCOUNT, <UserOutlined />),
                    _getItem(
                        Permission.DEV,
                        'Developer',
                        Paths.DEVTOOLS,
                        <CodeOutlined />,
                        [
                            getItem(
                                'JSON - ENV Parser',
                                Paths.DEVTOOLS_ENVPARSER,
                                <SwitcherOutlined />,
                            ),
                            getItem(
                                'Icons',
                                Paths.DEVTOOLS_ICONS,
                                <InfoCircleOutlined />,
                            ),
                            getItem(
                                'Base64',
                                Paths.DEVTOOLS_BASE64,
                                <FileTextOutlined />,
                            ),
                        ],
                    ),
                ]}
            />
        </Col>
    );
};
