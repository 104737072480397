/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Button, Input, Modal, Row, Table, Typography } from 'antd';
import { queryClient } from 'api';
import {
    useDeleteNotificationSchedulesMutation,
    useScheduleNotificationMutation,
} from 'api/mutations/useScheduleNotificationMutation';
import { useScheduleNotificationsQuery } from 'api/query/useScheduledNotificationsQuery';
import { queryKeys } from 'api/queryKeys';
import { Layout, Sidebar } from 'components';
import { useModalController } from 'hooks/useModalController';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import { NotiifcationColumns } from 'utils/getUserTableProps';
import { authenticate, register } from 'utils/webauthn';

export const Notifications = (): $MixedElement => {
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const scheduleModal = useModalController();
    const cancelModal = useModalController();

    const { data: schedules } = useScheduleNotificationsQuery();
    const scheduleNotificationMutation = useScheduleNotificationMutation();
    const deleteNotificationMutation = useDeleteNotificationSchedulesMutation();

    const dataSource = useMemo(() => {
        return schedules.map((schedule) => {
            const params = JSON.parse(schedule.params);
            return {
                id: schedule.id,
                message: params.message,
                title: params.title,
                key: schedule.id,
                index: schedule.id,
                date: schedule.scheduled_at,
            };
        });
    }, [schedules]);

    const trimmedMessage = message.trim();
    const trimmedTitle = title.trim();

    return (
        <Row wrap={false}>
            <Sidebar />
            <Layout>
                <Typography.Title level={1}>Notification</Typography.Title>
                <div className="bg-blue-100 space-y-2 flex min-w-[400px] flex-col w-max rounded-lg p-6 mb-4">
                    <Typography.Title level={5}>Create new:</Typography.Title>
                    <div className="w-[100%]">
                        <Input
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="w-full"
                            size="large"
                            placeholder="Title"
                        />
                    </div>
                    <div className="w-[100%]">
                        <Input
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="w-[100%]"
                            size="large"
                            placeholder="Message"
                        />
                    </div>

                    {schedules.length > 0 ? (
                        <Button
                            onClick={cancelModal.open}
                            danger
                            type="primary"
                        >
                            Cancel Scheduled Notifications
                        </Button>
                    ) : (
                        <>
                            <Button
                                disabled={
                                    trimmedMessage === '' ||
                                    trimmedTitle === '' ||
                                    schedules.length > 0
                                }
                                onClick={scheduleModal.open}
                                danger
                                type="primary"
                            >
                                {schedules.length > 0
                                    ? 'Schedule is already set'
                                    : 'Schedule'}
                            </Button>
                            <Button
                                type="dashed"
                                className="mt-1"
                                onClick={async () => {
                                    await register(
                                        'clave',
                                        'clave',
                                        'deaddeaddead',
                                    );
                                }}
                            >
                                No passkeys? Create one
                            </Button>
                        </>
                    )}
                </div>

                <Table
                    className="w-full"
                    dataSource={dataSource}
                    columns={NotiifcationColumns}
                />
            </Layout>
            <Modal
                title="This message will be broadcasted to all users. Are you sure?"
                open={scheduleModal.isOpen}
                onOk={async () => {
                    await authenticate([], 'deaddeaddead');
                    await scheduleNotificationMutation.mutateAsync({
                        message: trimmedMessage,
                        title: trimmedTitle,
                    });
                    scheduleModal.close();
                    setTimeout(() => {
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.SCHEDULED_NOTIFICATIONS],
                        });
                    }, 1000);
                    setTitle('');
                    setMessage('');
                }}
                okButtonProps={{
                    loading: scheduleNotificationMutation.isPending,
                }}
                onCancel={scheduleModal.close}
            >
                <div className="bg-blue-100 w-[100%] space-y-2 flex flex-col rounded-lg p-6 mb-4">
                    <span>Title:</span>
                    <Typography.Title level={4}>{title}</Typography.Title>
                    <span>Message:</span>
                    <Typography.Title level={4}>{message}</Typography.Title>
                </div>
            </Modal>

            <Modal
                title="Are you sure?"
                open={cancelModal.isOpen}
                onOk={async () => {
                    await deleteNotificationMutation.mutateAsync();
                    cancelModal.close();
                    setTimeout(() => {
                        queryClient.refetchQueries({
                            queryKey: [queryKeys.SCHEDULED_NOTIFICATIONS],
                        });
                    }, 1000);
                }}
                okButtonProps={{
                    loading: deleteNotificationMutation.isPending,
                }}
                onCancel={cancelModal.close}
            />
        </Row>
    );
};
