/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { ResponsiveLine, type Serie } from '@nivo/line';
import { Input, Pagination, Row, Spin, Table, Tabs, Typography } from 'antd';
import { PROVIDER } from 'api';
import { useGetUsersMutation } from 'api/mutations/useGetUsersMutation';
import { useGetUserStatsQuery } from 'api/query/useGetUserStatsQuery';
import { Layout, Sidebar } from 'components';
import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { useDebounce } from 'hooks/useDebounce';
import { useEffect, useMemo, useState } from 'react';
import { type $MixedElement } from 'types';
import { UserColumns, tabItems } from 'utils/getUserTableProps';

type Tab = 'stats' | 'users' | 'dune' | 'paymasterBalance';

export const Dashboard = (): $MixedElement => {
    const [tab, setTab] = useState<Tab>('stats');

    const onTabChange = (key: string): void => {
        setTab(key as Tab);
        window.location.hash = key;
    };

    useEffect(() => {
        const hash = window.location.hash.slice(1);
        if (hash) {
            onTabChange(hash);
        }
    }, []);

    return (
        <Row wrap={false}>
            <Sidebar />
            <Layout>
                <>
                    <Typography.Title className="mb-0" level={1}>
                        Dashboard
                    </Typography.Title>
                    <Tabs
                        activeKey={tab}
                        onChange={onTabChange}
                        items={tabItems}
                    />
                    {tab === 'users' ? (
                        <UsersList />
                    ) : tab === 'stats' ? (
                        <UserStats />
                    ) : tab === 'paymasterBalance' ? (
                        <PaymasterBalance />
                    ) : (
                        <DuneStats />
                    )}
                </>
            </Layout>
        </Row>
    );
};

const UsersList = (): $MixedElement => {
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search);

    const [mutation, users] = useGetUsersMutation({
        debouncedSearch,
        page,
    });

    const dataSource = useMemo(() => {
        return users.data.map((user, index) => {
            return {
                id: user.id,
                email: user.owner?.email,
                username: user.username,
                isDeployed: user.is_deployed ? 'true' : 'false',
                created_at: user.created_at,
                address: user.address,
                key: index,
            };
        });
    }, [users.data]);

    return (
        <>
            <Row className="items-center mb-4" justify="end">
                <Row className="items-center">
                    <Typography.Text>Count: {users.count}</Typography.Text>
                    <Input
                        size="large"
                        placeholder="Search by address/username"
                        className="w-[286px] ml-2"
                        value={search}
                        onChange={(e): void => {
                            setSearch(e.target.value);
                            setPage(0);
                        }}
                    />
                </Row>
            </Row>
            {mutation.isPending && (
                <Spin tip="Loading" size="small">
                    <div className="p-12 bg-gray-100 rounded-sm" />
                </Spin>
            )}
            {!mutation.isPending && users.data.length > 0 && (
                <>
                    <div className="max-h-[80vh] overflow-auto">
                        <Table
                            pagination={false}
                            dataSource={dataSource}
                            columns={UserColumns}
                        />
                    </div>
                    <Pagination
                        onChange={(e): void => {
                            setPage(e - 1);
                        }}
                        pageSize={50}
                        showSizeChanger={false}
                        className="mt-5 mx-auto"
                        current={page + 1}
                        defaultCurrent={1}
                        total={users.count}
                    />
                </>
            )}
        </>
    );
};

const UserStats = (): $MixedElement => {
    const { data, isLoading } = useGetUserStatsQuery();

    const chartData = useMemo(() => {
        const usersByDate = data.users_by_date;

        const formatDate = (date: string): string => {
            const pad = (n: number): string => (n < 10 ? `0${n}` : `${n}`);
            const _date = new Date(date);
            return `${pad(_date.getDate())}/${pad(_date.getMonth() + 1)}`;
        };

        let totalCount = 0;
        const cumulativeData = usersByDate.map((item, index) => {
            totalCount = totalCount + Number(item.count);
            return {
                x: formatDate(item.date),
                y: totalCount,
                key: index,
            };
        });

        const byDateCount = usersByDate.map((item, index) => {
            totalCount = totalCount + Number(item.count);
            return {
                x: formatDate(item.date),
                y: item.count,
                key: index,
            };
        });

        return {
            cumulative: [
                {
                    id: 'users',
                    color: 'hsl(184, 70%, 50%)',
                    data: cumulativeData,
                },
            ],
            byDate: [
                {
                    id: 'users',
                    color: 'hsl(184, 70%, 50%)',
                    data: byDateCount,
                },
            ],
        };
    }, [data.users_by_date]);

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }
    return (
        <>
            <div className="flex px-[50px] space-x-3">
                <div className="w-[200px] shrink-0 p-4 pb-10 bg-blue-100 border-blue-300 border-1 rounded-lg">
                    <p className="text-lg">Users registered:</p>
                    <p className="text-3xl font-bold">{data.count}</p>
                </div>
                <div className="w-[200px] shrink-0 p-4 pb-10 bg-blue-100 border-blue-300 border-1 rounded-lg">
                    <p className="text-lg">Users Deployed:</p>
                    <p className="text-3xl font-bold">{data.deployed_count}</p>
                </div>
            </div>
            <h2 className="text-lg mt-4">By date</h2>
            <div className="w-[100%] min-h-[80vh]">
                <ChartView chartData={chartData.byDate} />
            </div>
            <h2 className="text-lg">Cumulative</h2>
            <div className="w-[100%] min-h-[80vh]">
                <ChartView chartData={chartData.cumulative} />
            </div>
        </>
    );
};

const ChartView = ({
    chartData,
}: {
    chartData: Array<Serie>;
}): $MixedElement => {
    return (
        <ResponsiveLine
            data={chartData}
            colors={['rgba(0, 206, 209, 1']}
            axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickRotation: -60,
                legend: 'Date',
                legendOffset: 36,
                legendPosition: 'middle',
            }}
            theme={{
                background: '#ffffff',
                grid: {
                    line: {
                        strokeWidth: 1,
                    },
                },
                axis: {
                    ticks: {
                        text: {
                            fontSize: 10,
                        },
                    },
                },
            }}
            useMesh={true}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Count',
                legendOffset: -40,
                legendPosition: 'middle',
            }}
            margin={{ top: 50, right: 40, bottom: 50, left: 60 }}
        />
    );
};

const DuneStats = (): $MixedElement => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);

    return (
        <>
            {loading && (
                <Spin tip="Loading" size="small">
                    <div className="p-12 bg-gray-100 rounded-sm" />
                </Spin>
            )}

            <div
                className="flex flex-wrap"
                style={{
                    opacity: loading ? 0 : 1,
                }}
            >
                <iframe
                    src="https://dune.com/embeds/3440524/5781736/"
                    width={300}
                    height={300}
                />
                <iframe
                    src="https://dune.com/embeds/3441751/5782455/"
                    width={300}
                    height={300}
                />
                <iframe
                    src="https://dune.com/embeds/3441817/5782558/ede52fe5-8776-4dc0-843b-692657c073e0/"
                    width={300}
                    height={300}
                />
                <iframe
                    src="https://dune.com/embeds/3441871/5782590/927c0a3a-d520-43dc-9c16-bcea5dfa3e41/"
                    width={300}
                    height={300}
                />
            </div>
            <div className="flex flex-wrap">
                <iframe
                    src="https://dune.com/embeds/3441787/5782482/"
                    width={1200}
                    height={400}
                />
            </div>
            <div className="flex flex-wrap">
                <iframe
                    src="https://dune.com/embeds/3441742/5782352/"
                    width={1200}
                    height={400}
                />
            </div>
        </>
    );
};

const PaymasterBalance = (): $MixedElement => {
    const [loading, setLoading] = useState(true);
    const [gaslessPaymasterBalance, setGaslessPaymasterBalance] = useState(
        BigNumber.from(0),
    );
    const [erc20PaymasterBalance, setERC20PaymasterBalance] = useState(
        BigNumber.from(0),
    );
    const [deployerBalance, setDeployerBalance] = useState(BigNumber.from(0));

    const addresses = {
        DEPLOYER: '0x40C28929fBD647c229F446C21090aD83431FD24E',
        GASLESS_PAYMASTER: '0xCBf1367699d8704a320ADF06E23c294AC089270B',
        ERC20_PAYMASTER: '0x7a862CC27FAD666aB180F1708f8b18F7892FA761',
    };

    useEffect(() => {
        const main = async (): Promise<void> => {
            const _gaslessBalance = await PROVIDER.getBalance(
                addresses.GASLESS_PAYMASTER,
            );
            const _erc20Balance = await PROVIDER.getBalance(
                addresses.ERC20_PAYMASTER,
            );
            const _deployerBalance = await PROVIDER.getBalance(
                addresses.DEPLOYER,
            );
            setGaslessPaymasterBalance(_gaslessBalance);
            setERC20PaymasterBalance(_erc20Balance);
            setDeployerBalance(_deployerBalance);
            setLoading(false);
        };
        main();
    }, []);

    if (loading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }

    return (
        <div className="flex px-[50px] space-x-3">
            <div className="w-[200px] shrink-0 p-4 pb-10 bg-blue-100 border-blue-300 border-1 rounded-lg">
                <p className="text-lg">
                    {' '}
                    <a
                        href={`https://explorer.zksync.io/address/${addresses.GASLESS_PAYMASTER}`}
                        target="_blank"
                    >
                        {' '}
                        Gasless Paymaster Balance:{' '}
                    </a>{' '}
                </p>
                <p className="text-3xl font-bold">
                    {Number(formatEther(gaslessPaymasterBalance)).toFixed(3)}{' '}
                    ETH
                </p>
            </div>
            <div className="w-[200px] shrink-0 p-4 pb-10 bg-blue-100 border-blue-300 border-1 rounded-lg">
                <p className="text-lg">
                    {' '}
                    <a
                        href={`https://explorer.zksync.io/address/${addresses.ERC20_PAYMASTER}`}
                        target="_blank"
                    >
                        {' '}
                        ERC20 Paymaster Balance:{' '}
                    </a>{' '}
                </p>
                <p className="text-3xl font-bold">
                    {Number(formatEther(erc20PaymasterBalance)).toFixed(3)} ETH
                </p>
            </div>
            <div className="w-[200px] shrink-0 p-4 pb-10 bg-blue-100 border-blue-300 border-1 rounded-lg">
                <p className="text-lg">
                    {' '}
                    <a
                        href={`https://explorer.zksync.io/address/${addresses.DEPLOYER}`}
                        target="_blank"
                    >
                        {' '}
                        Deployer Address Balance:{' '}
                    </a>{' '}
                </p>
                <p className="text-3xl font-bold">
                    {Number(formatEther(deployerBalance)).toFixed(3)} ETH
                </p>
            </div>
        </div>
    );
};
