/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import type { BarDatum, BarLegendProps } from '@nivo/bar';
import type { OrdinalColorScaleConfig } from '@nivo/colors';
import type {
    DatumValue,
    Margin,
    PropertyAccessor,
    ValueFormat,
} from '@nivo/core';
import type { LegendProps } from '@nivo/legends';
import type { erc20Abi } from 'viem';

export enum ChartInterval {
    Daily = 'daily',
    Weekly = 'weekly',
    Cumulative = 'cumulative',
    Monthly = 'monthly',
}

export enum ChartType {
    Line = 'line',
    Bar = 'bar',
}

export type DataKeys =
    | 'activeAccounts'
    | 'createdAccounts'
    | 'transactions'
    | 'gasSponsored';

export type LineChartProps = {
    axisBottomLegend: string;
    axisLeftLegend: string;
    axisLeftTickValues: number;
    axisLeftFormat: ValueFormat<number> | undefined;
    yFormat: ValueFormat<DatumValue> | undefined;
    margin: Partial<Margin>;
};

export type LineData = ReadonlyArray<{
    x: string;
    y: number;
}>;

export type LineChartData = ReadonlyArray<{
    id: string;
    data: LineData;
}>;

export type BarChartProps<K extends string> = {
    keys: ReadonlyArray<K>;
    indexBy: PropertyAccessor<Record<string, string | number>, string>;
    axisBottomLegend: string;
    axisLeftLegend: string;
    enableTotals: boolean;
    groupMode?: 'grouped' | 'stacked';
    valueFormat?: ValueFormat<number>;
    logScale?: {
        type: 'symlog';
        constant?: number;
        min?: 'auto' | number;
        max?: 'auto' | number;
        reverse?: boolean;
    };
    colors?: OrdinalColorScaleConfig<BarDatum>;
    disableAxisLeft?: boolean;
    legends?: ReadonlyArray<BarLegendProps>;
};

export type BarChartData<K extends string> = {
    date: string;
} & {
    [P in K]: number;
};

export type PieChartData = {
    id: string | number;
    label: string | number;
    value: number;
};

export type PieChartProps = {
    legends?: ReadonlyArray<LegendProps>;
    colors?: OrdinalColorScaleConfig<PieChartData>;
    valueFormat?: ValueFormat<number>;
};

// user address -> token address -> balance
export type UserBalances = Record<string, Record<string, number>>;

export type Contract = {
    address: `0x${string}`;
    abi: typeof erc20Abi;
    functionName: 'balanceOf';
    args: ReadonlyArray<`0x${string}`>;
};

export type UserInfo = Record<
    string,
    {
        username: string | null;
        mail: string | null;
    }
>;

export type TxDistribution = Record<
    string,
    {
        swap: number;
        invest: number;
        peanut: number;
        transfer: number;
    }
>;
