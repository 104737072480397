/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Card, Col, Divider, Row, Spin, Typography } from 'antd';
import { useMemo } from 'react';
import type { $MixedElement } from 'types';
import {
    ETH_ADDRESS,
    GRAPH_ETH_ADDRESS,
    type PieChartData,
    type UserBalances,
    addressToToken,
    formatUnits,
} from 'utils';

import { BarChart } from '../charts/BarChart';
import { PieChart } from '../charts/PieChart';
import type { StatsQuery } from '.graphclient';

const { Title } = Typography;

type PieChartsProps = {
    data: StatsQuery;
    isLoading: boolean;
    tokenPrices: Record<string, number> | null;
    balancesUSD: UserBalances | null;
};

export const PieCharts = ({
    data,
    isLoading,
    tokenPrices,
    balancesUSD,
}: PieChartsProps): $MixedElement => {
    const backupChartData = useMemo(() => {
        const backedUp = data.total?.backedUp ?? 0;
        const deployed = data.total?.deployedAccounts ?? 0;
        const total = data.total?.createdAccounts ?? 0;

        return [
            { id: 'Have Backup', label: 'Have Backup', value: backedUp },
            {
                id: 'Not Deployed',
                label: 'Not Deployed',
                value: total - deployed,
            },
            { id: 'No Backup', label: 'No Backup', value: deployed - backedUp },
        ];
    }, [data]);

    const balanceChartData = useMemo(() => {
        if (!balancesUSD || !tokenPrices || !data) return [];

        const totalValuePerToken = Object.values(balancesUSD).reduce<
            Record<string, number>
        >((acc, balance) => {
            Object.entries(balance).forEach(([tokenAddress, amount]) => {
                acc[tokenAddress] = (acc[tokenAddress] ?? 0) + amount;
            });
            return acc;
        }, {});

        const totalValuePerEarn = data.months.reduce<Record<string, number>>(
            (acc, month) => {
                month.investFlow.forEach((flow) => {
                    const tokenAddress =
                        flow.erc20 === GRAPH_ETH_ADDRESS
                            ? ETH_ADDRESS
                            : (flow.erc20 as string);
                    const token = addressToToken[tokenAddress];
                    if (!token) return;

                    const id = `${token.symbol}-${flow.protocol}`;
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const inflow =
                        formatUnits(flow.amountIn, token.decimals ?? 18) *
                        tokenPrice;
                    const outflow =
                        formatUnits(flow.amountOut, token.decimals ?? 18) *
                        tokenPrice;
                    acc[id] = (acc[id] ?? 0) + (inflow - outflow);
                });
                return acc;
            },
            {},
        );

        const balanceChartData: Array<PieChartData> = [];
        const others: PieChartData = {
            id: 'Others',
            label: 'Others',
            value: 0,
        };

        // Process token balances
        Object.entries(totalValuePerToken).forEach(
            ([tokenAddress, totalValue]) => {
                if (totalValue < 1) return;
                const token = addressToToken[tokenAddress];
                if (!token || !token.symbol || totalValue < 200) {
                    others.value += totalValue;
                } else {
                    balanceChartData.push({
                        id: token.symbol,
                        label: token.symbol,
                        value: totalValue,
                    });
                }
            },
        );

        // Add earn balances
        Object.entries(totalValuePerEarn).forEach(([id, totalValue]) => {
            if (totalValue < 1) return;
            if (totalValue < 200) {
                others.value += totalValue;
            } else {
                balanceChartData.push({ id, label: id, value: totalValue });
            }
        });

        if (others.value > 0) balanceChartData.push(others);

        return balanceChartData;
    }, [balancesUSD, data, tokenPrices]);

    const sortedBalanceChartData = useMemo(
        () => balanceChartData.sort((a, b) => b.value - a.value),
        [balanceChartData],
    );

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-[80vh]">
                <Spin size="large" tip="Loading charts..." />
            </div>
        );
    }

    return (
        <div className="w-full bg-gray-50 p-6">
            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <Card className="shadow-md hover:shadow-lg transition-shadow duration-300">
                        <Title
                            level={2}
                            className="mb-6 text-center text-blue-600"
                        >
                            Token Balances
                        </Title>
                        <Divider className="mb-6" />
                        <Row gutter={[16, 16]}>
                            <Col xs={24} lg={8}>
                                <div className="h-[500px] bg-white rounded-lg shadow-inner p-4">
                                    {' '}
                                    {/* Increased height */}
                                    <PieChart
                                        data={sortedBalanceChartData.slice(
                                            0,
                                            10,
                                        )}
                                        props={{
                                            valueFormat: ' >-$,.0f', // Remove decimals
                                            colors: { scheme: 'nivo' },
                                            legends: [], // Remove legends
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col xs={24} lg={16}>
                                <div className="h-[500px] bg-white rounded-lg shadow-inner p-4">
                                    <BarChart
                                        data={sortedBalanceChartData}
                                        props={{
                                            keys: ['value'],
                                            disableAxisLeft: true,
                                            indexBy: 'id',
                                            valueFormat: ' >-$,.0f',
                                            axisBottomLegend: 'Token/Protocol',
                                            axisLeftLegend: 'Balance (USD)',
                                            colors: { scheme: 'nivo' },
                                            enableTotals: false,
                                            logScale: {
                                                type: 'symlog',
                                                min: 'auto',
                                                max: 'auto',
                                                constant: 1000,
                                            },
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card className="shadow-md hover:shadow-lg transition-shadow duration-300">
                        <Title
                            level={2}
                            className="mb-6 text-center text-green-600"
                        >
                            Backup Status
                        </Title>
                        <Divider className="mb-6" />
                        <div className="h-[500px] bg-white rounded-lg shadow-inner p-4">
                            {' '}
                            {/* Increased height */}
                            <PieChart
                                data={backupChartData}
                                props={{
                                    valueFormat: undefined,
                                    colors: { scheme: 'accent' },
                                    legends: [],
                                }}
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
