/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { type TabsProps } from 'antd';

export const NotiifcationColumns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        className: 'whitespace-pre-wrap',
    },
];

export const UserColumns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
        className: 'whitespace-pre-wrap',
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        className: 'whitespace-pre-wrap',
    },

    {
        title: 'Is Deployed',
        dataIndex: 'isDeployed',
        key: 'isDeployed',
        className: 'whitespace-pre-wrap',
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        className: 'whitespace-pre-wrap',
    },
];

export const tabItems: TabsProps['items'] = [
    {
        key: 'stats',
        label: 'Statistics',
        children: null,
    },
    {
        key: 'dune',
        label: 'Dune',
        children: null,
    },
    {
        key: 'users',
        label: 'Users',
        children: null,
    },

    {
        key: 'paymasterBalance',
        label: 'Paymaster Balance',
        children: null,
    },
];
