/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import { Segmented, Spin, type TimeRangePickerProps } from 'antd';
import { BarChart } from 'components/charts/BarChart';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    type BarChartData,
    type BarChartProps,
    ChartInterval,
    ChartType,
    ETH_ADDRESS,
    type LineChartData,
    type LineChartProps,
    RangePicker,
    bytesToDate,
    bytesToNumber,
    dateFormat,
    formatUnits,
    getDays,
} from 'utils';

import { LineChart } from '../charts/LineChart';
import type { StatsQuery } from '.graphclient';

export const GasSponsored = ({
    data,
    isLoading,
    tokenPrices,
    defaultChartInterval = ChartInterval.Weekly,
    isPreview = false,
}: {
    data: StatsQuery;
    isLoading: boolean;
    tokenPrices: Record<string, number> | null;
    defaultChartInterval?: ChartInterval;
    isPreview?: boolean;
}): $MixedElement => {
    const [dateRange, setDateRange] = useState<[number, number]>([0, 0]);
    const [chartInterval, setChartInterval] =
        useState<ChartInterval>(defaultChartInterval);
    const [chartType, setChartType] = useState<ChartType>(ChartType.Line);

    const onDateChange: TimeRangePickerProps['onChange'] = (date) => {
        if (date && date[0] && date[1]) {
            setDateRange([date[0].unix(), date[1].unix()]);
            setChartInterval(ChartInterval.Daily);
        }
    };

    const chartData = useMemo(() => {
        switch (chartType) {
            case ChartType.Line: {
                if (tokenPrices == null) {
                    return {
                        [ChartInterval.Daily]: [],
                        [ChartInterval.Weekly]: [],
                        [ChartInterval.Monthly]: [],
                        [ChartInterval.Cumulative]: [],
                    };
                }
                const ethPrice = tokenPrices[ETH_ADDRESS];

                const days = getDays(data.days, dateRange);

                const dailyData = days.map((day) => {
                    const gasSponsored = day.gasSponsored;
                    const gasSponsoredUSD =
                        formatUnits(gasSponsored, 18) * ethPrice;
                    console.log('gasSponsoredUSD', gasSponsoredUSD);

                    return {
                        x: bytesToDate(day.id),
                        y: gasSponsoredUSD,
                    };
                });

                const { weeklyData, cumulativeData } = data.weeks.reduce(
                    (acc, week) => {
                        const gasSponsored = week.gasSponsored;
                        const gasSponsoredUSD =
                            formatUnits(gasSponsored, 18) * ethPrice;

                        acc.weeklyData.push({
                            x: bytesToDate(week.id),
                            y: gasSponsoredUSD,
                        });

                        acc.cumulativeCount += gasSponsoredUSD;
                        acc.cumulativeData.push({
                            x: bytesToDate(week.id),
                            y: acc.cumulativeCount,
                        });

                        return acc;
                    },
                    {
                        weeklyData: [] as Array<{ x: string; y: number }>,
                        cumulativeData: [] as Array<{ x: string; y: number }>,
                        cumulativeCount: 0,
                    },
                );

                const monthlyData = data.months.map((month) => {
                    const gasSponsored = month.gasSponsored;
                    const gasSponsoredUSD =
                        formatUnits(gasSponsored, 18) * ethPrice;

                    return {
                        x: bytesToDate(month.id),
                        y: gasSponsoredUSD,
                    };
                });

                return {
                    [ChartInterval.Daily]: [
                        {
                            id: 'daily gas sponsored',
                            data: dailyData,
                        },
                    ],
                    [ChartInterval.Weekly]: [
                        {
                            id: 'weekly gas sponsored',
                            data: weeklyData,
                        },
                    ],
                    [ChartInterval.Monthly]: [
                        {
                            id: 'monthly gas sponsored',
                            data: monthlyData,
                        },
                    ],
                    [ChartInterval.Cumulative]: [
                        {
                            id: 'cumulative gas sponsored',
                            data: cumulativeData,
                        },
                    ],
                };
            }
            case ChartType.Bar: {
                if (tokenPrices == null) {
                    return {
                        [ChartInterval.Daily]: [],
                        [ChartInterval.Weekly]: [],
                        [ChartInterval.Monthly]: [],
                        [ChartInterval.Cumulative]: [],
                    };
                }
                const ethPrice = tokenPrices[ETH_ADDRESS];

                const days = data.days.filter((day) => {
                    return (
                        bytesToNumber(day.id) >= dateRange[0] &&
                        bytesToNumber(day.id) <= dateRange[1]
                    );
                });

                const dailyData = days.map((day) => {
                    const gasSponsored = day.gasSponsored;
                    const gasSponsoredUSD =
                        formatUnits(gasSponsored, 18) * ethPrice;

                    return {
                        date: bytesToDate(day.id),
                        count: gasSponsoredUSD,
                    };
                });

                const weeklyData = data.weeks.map((week) => {
                    const gasSponsored = week.gasSponsored;
                    const gasSponsoredUSD =
                        formatUnits(gasSponsored, 18) * ethPrice;

                    return {
                        date: bytesToDate(week.id),
                        count: gasSponsoredUSD,
                    };
                });

                const monthlyData = data.months.map((month) => {
                    const gasSponsored = month.gasSponsored;
                    const gasSponsoredUSD =
                        formatUnits(gasSponsored, 18) * ethPrice;

                    return {
                        date: bytesToDate(month.id),
                        count: gasSponsoredUSD,
                    };
                });

                const cumulativeData = data.weeks.reduce(
                    (acc: Array<BarChartData<'count'>>, week) => {
                        const gasSponsored = week.gasSponsored;
                        const gasSponsoredUSD =
                            formatUnits(gasSponsored, 18) * ethPrice;
                        const lastValue =
                            acc.length > 0 ? acc[acc.length - 1].count : 0;

                        acc.push({
                            date: bytesToDate(week.id),
                            count: lastValue + gasSponsoredUSD,
                        });

                        return acc;
                    },
                    [],
                );

                return {
                    [ChartInterval.Daily]: dailyData,
                    [ChartInterval.Weekly]: weeklyData,
                    [ChartInterval.Monthly]: monthlyData,
                    [ChartInterval.Cumulative]: cumulativeData,
                };
            }
        }
    }, [data, tokenPrices, chartType, dateRange]);

    const lineProps: LineChartProps = {
        axisBottomLegend: 'Date',
        axisLeftLegend: 'USD',
        axisLeftTickValues: 10,
        axisLeftFormat: ' >-.2f',
        yFormat: ' >-$.2f',
        margin: isPreview
            ? { top: 20, right: 50, bottom: 50, left: 50 }
            : { top: 40, right: 200, bottom: 100, left: 100 },
    };

    const barProps: BarChartProps<'count'> = {
        keys: ['count'],
        indexBy: 'date',
        axisBottomLegend: 'Date',
        axisLeftLegend: 'USD',
        enableTotals: false,
        valueFormat: ' >-$.2f',
    };

    if (isLoading || tokenPrices == null) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }
    return (
        <div className="w-[100%] min-h-[40vh] max-h-[100vh]">
            {!isPreview && (
                <>
                    <Segmented
                        options={[
                            ChartInterval.Daily,
                            ChartInterval.Weekly,
                            ChartInterval.Monthly,
                            ChartInterval.Cumulative,
                        ]}
                        value={chartInterval}
                        defaultValue={defaultChartInterval}
                        onChange={(value): void => {
                            if (
                                value === ChartInterval.Daily &&
                                dateRange[0] === 0
                            ) {
                                setDateRange([
                                    dayjs().subtract(1, 'week').unix() - 80000,
                                    dayjs().unix(),
                                ]);
                            }
                            setChartInterval(value as ChartInterval);
                        }}
                        style={{ marginBottom: 12, marginLeft: 12 }}
                    />
                    <Segmented
                        options={[ChartType.Line, ChartType.Bar]}
                        onChange={(value): void => {
                            setChartType(value as ChartType);
                        }}
                        style={{ marginBottom: 12, marginLeft: 24 }}
                    />
                    <RangePicker
                        onChange={onDateChange}
                        value={
                            dateRange[0] === 0
                                ? undefined
                                : [
                                      dayjs.unix(dateRange[0]),
                                      dayjs.unix(dateRange[1]),
                                  ]
                        }
                        minDate={dayjs('2024-01-21', dateFormat)}
                        maxDate={dayjs()}
                        style={{ marginLeft: 24 }}
                    />
                </>
            )}
            {chartType === ChartType.Line ? (
                <LineChart
                    data={chartData[chartInterval] as LineChartData}
                    props={lineProps}
                />
            ) : (
                <BarChart
                    data={
                        chartData[chartInterval] as Array<BarChartData<'count'>>
                    }
                    props={barProps}
                />
            )}
        </div>
    );
};
