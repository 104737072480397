/**
 * Copyright Clave - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */
import {
    Col,
    InputNumber,
    type InputNumberProps,
    Row,
    Segmented,
    Slider,
    Spin,
    type TimeRangePickerProps,
} from 'antd';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import type { $MixedElement } from 'types';
import {
    type BarChartData,
    type BarChartProps,
    ChartInterval,
    ETH_ADDRESS,
    GRAPH_ETH_ADDRESS,
    RangePicker,
    bytesToDate,
    dateFormat,
    formatUnits,
    getDays,
    symbolToAddress,
    tokenAddressToDecimal,
} from 'utils';

import { BarChart } from '../charts/BarChart';
import type { StatsQuery } from '.graphclient';

export const EarnStats = ({
    data,
    isLoading,
    tokenPrices,
    defaultChartInterval = ChartInterval.Weekly,
    isPreview = false,
}: {
    data: StatsQuery;
    isLoading: boolean;
    tokenPrices: Record<string, number> | null;
    defaultChartInterval?: ChartInterval;
    isPreview?: boolean;
}): $MixedElement => {
    const [dateRange, setDateRange] = useState<[number, number]>([0, 0]);
    const [chartInterval, setChartInterval] =
        useState<ChartInterval>(defaultChartInterval);

    const [scale, setScale] = useState(10000);
    const onChange: InputNumberProps['onChange'] = (newValue) => {
        if (typeof newValue === 'number') setScale(newValue);
    };

    type Key =
        | 'koi'
        | 'clave'
        | 'syncswap'
        | 'zerolend_dai'
        | 'zerolend_usdc'
        | 'zerolend_tether'
        | 'zerolend_other'
        | 'meow';

    const onDateChange: TimeRangePickerProps['onChange'] = (date) => {
        if (date && date[0] && date[1]) {
            setDateRange([date[0].unix(), date[1].unix()]);
            setChartInterval(ChartInterval.Daily);
        }
    };

    const chartData = useMemo(() => {
        const daily: Array<BarChartData<Key>> = [];
        const weekly: Array<BarChartData<Key>> = [];
        const monthly: Array<BarChartData<Key>> = [];
        const cumulative: Array<BarChartData<Key>> = [];

        if (!tokenPrices || !data) {
            return {
                [ChartInterval.Daily]: daily,
                [ChartInterval.Weekly]: weekly,
                [ChartInterval.Monthly]: monthly,
                [ChartInterval.Cumulative]: cumulative,
            };
        }

        const days = getDays(data.days, dateRange);
        days.forEach((day) => {
            const dailyData: BarChartData<Key> = {
                date: bytesToDate(day.id),
                koi: 0,
                clave: 0,
                zerolend_dai: 0,
                zerolend_tether: 0,
                zerolend_usdc: 0,
                zerolend_other: 0,
                syncswap: 0,
                meow: 0,
            };

            const protocolToInvested = day.investFlow.reduce(
                (acc, flow) => {
                    let tokenAddress = flow.erc20.toLowerCase();
                    if (tokenAddress === GRAPH_ETH_ADDRESS)
                        tokenAddress = ETH_ADDRESS;
                    const decimal = tokenAddressToDecimal[tokenAddress] ?? 18;
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const inflow = formatUnits(flow.amountIn, decimal);
                    const inflowUsdc = inflow * tokenPrice;
                    const outflow = formatUnits(flow.amountOut, decimal);
                    const outflowUsdc = outflow * tokenPrice;
                    switch (flow.protocol) {
                        case 'Koi':
                            acc['koi'] += inflowUsdc - outflowUsdc;
                            break;
                        case 'Clave':
                            acc['clave'] += inflowUsdc - outflowUsdc;
                            break;
                        case 'ZeroLend':
                            switch (tokenAddress) {
                                case symbolToAddress['DAI']:
                                    acc['zerolend_dai'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                                case symbolToAddress['USDT']:
                                    acc['zerolend_tether'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                                case symbolToAddress['USDC.e']:
                                    acc['zerolend_usdc'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                                default:
                                    acc['zerolend_other'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                            }
                            break;
                        case 'SyncSwap':
                            acc['syncswap'] += inflowUsdc - outflowUsdc;
                            break;
                        case 'Meow':
                            acc['meow'] += inflowUsdc - outflowUsdc;
                            break;
                    }
                    return acc;
                },
                {
                    koi: 0,
                    clave: 0,
                    syncswap: 0,
                    zerolend_usdc: 0,
                    zerolend_tether: 0,
                    zerolend_dai: 0,
                    zerolend_other: 0,
                    meow: 0,
                } as Record<Key, number>,
            );

            dailyData.koi = protocolToInvested['koi'];
            dailyData.clave = protocolToInvested['clave'];
            dailyData.zerolend_usdc = protocolToInvested['zerolend_usdc'];
            dailyData.zerolend_tether = protocolToInvested['zerolend_tether'];
            dailyData.zerolend_dai = protocolToInvested['zerolend_dai'];
            dailyData.zerolend_other = protocolToInvested['zerolend_other'];
            dailyData.syncswap = protocolToInvested['syncswap'];
            daily.push(dailyData);
        });

        data.weeks.slice(10).forEach((week) => {
            const weeklyData: BarChartData<Key> = {
                date: bytesToDate(week.id),
                koi: 0,
                clave: 0,
                zerolend_dai: 0,
                zerolend_tether: 0,
                zerolend_usdc: 0,
                zerolend_other: 0,
                syncswap: 0,
                meow: 0,
            };

            const cumulativeData =
                cumulative.length > 0
                    ? {
                          ...cumulative[cumulative.length - 1],
                          date: bytesToDate(week.id),
                      }
                    : { ...weeklyData };

            const protocolToInvested = week.investFlow.reduce(
                (acc, flow) => {
                    let tokenAddress = flow.erc20.toLowerCase();
                    if (tokenAddress === GRAPH_ETH_ADDRESS)
                        tokenAddress = ETH_ADDRESS;
                    const decimal = tokenAddressToDecimal[tokenAddress] ?? 18;
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const inflow = formatUnits(flow.amountIn, decimal);
                    const inflowUsdc = inflow * tokenPrice;
                    const outflow = formatUnits(flow.amountOut, decimal);
                    const outflowUsdc = outflow * tokenPrice;
                    switch (flow.protocol) {
                        case 'Koi':
                            acc['koi'] += inflowUsdc - outflowUsdc;
                            break;
                        case 'Clave':
                            acc['clave'] += inflowUsdc - outflowUsdc;
                            break;
                        case 'ZeroLend':
                            switch (tokenAddress) {
                                case symbolToAddress['DAI']:
                                    acc['zerolend_dai'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                                case symbolToAddress['USDT']:
                                    acc['zerolend_tether'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                                case symbolToAddress['USDC.e']:
                                    acc['zerolend_usdc'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                                default:
                                    acc['zerolend_other'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                            }
                            break;
                        case 'SyncSwap':
                            acc['syncswap'] += inflowUsdc - outflowUsdc;
                            break;
                        case 'Meow':
                            acc['meow'] += inflowUsdc - outflowUsdc;
                            break;
                    }
                    return acc;
                },
                {
                    koi: 0,
                    clave: 0,
                    syncswap: 0,
                    zerolend_usdc: 0,
                    zerolend_tether: 0,
                    zerolend_dai: 0,
                    zerolend_other: 0,
                    meow: 0,
                } as Record<Key, number>,
            );

            weeklyData.koi = protocolToInvested['koi'];
            weeklyData.clave = protocolToInvested['clave'];
            weeklyData.syncswap = protocolToInvested['syncswap'];
            weeklyData.zerolend_usdc = protocolToInvested['zerolend_usdc'];
            weeklyData.zerolend_tether = protocolToInvested['zerolend_tether'];
            weeklyData.zerolend_dai = protocolToInvested['zerolend_dai'];
            weeklyData.zerolend_other = protocolToInvested['zerolend_other'];
            weeklyData.meow = protocolToInvested['meow'];
            cumulativeData.koi += weeklyData.koi;
            cumulativeData.clave += weeklyData.clave;
            cumulativeData.syncswap += weeklyData.syncswap;
            cumulativeData.zerolend_usdc += weeklyData.zerolend_usdc;
            cumulativeData.zerolend_tether += weeklyData.zerolend_tether;
            cumulativeData.zerolend_dai += weeklyData.zerolend_dai;
            cumulativeData.zerolend_other += weeklyData.zerolend_other;
            cumulativeData.meow += weeklyData.meow;
            weekly.push(weeklyData);
            cumulative.push(cumulativeData);
        });

        data.months.slice(2).forEach((month) => {
            const monthlyData: BarChartData<Key> = {
                date: bytesToDate(month.id),
                koi: 0,
                clave: 0,
                zerolend_dai: 0,
                zerolend_tether: 0,
                zerolend_usdc: 0,
                zerolend_other: 0,
                syncswap: 0,
                meow: 0,
            };

            const protocolToInvested = month.investFlow.reduce(
                (acc, flow) => {
                    let tokenAddress = flow.erc20.toLowerCase();
                    if (tokenAddress === GRAPH_ETH_ADDRESS)
                        tokenAddress = ETH_ADDRESS;
                    const decimal = tokenAddressToDecimal[tokenAddress] ?? 18;
                    const tokenPrice = tokenPrices[tokenAddress] ?? 0;
                    const inflow = formatUnits(flow.amountIn, decimal);
                    const inflowUsdc = inflow * tokenPrice;
                    const outflow = formatUnits(flow.amountOut, decimal);
                    const outflowUsdc = outflow * tokenPrice;
                    switch (flow.protocol) {
                        case 'Koi':
                            acc['koi'] += inflowUsdc - outflowUsdc;
                            break;
                        case 'Clave':
                            acc['clave'] += inflowUsdc - outflowUsdc;
                            break;
                        case 'ZeroLend':
                            switch (tokenAddress) {
                                case symbolToAddress['DAI']:
                                    acc['zerolend_dai'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                                case symbolToAddress['USDT']:
                                    acc['zerolend_tether'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                                case symbolToAddress['USDC']:
                                    acc['zerolend_usdc'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                                default:
                                    acc['zerolend_other'] +=
                                        inflowUsdc - outflowUsdc;
                                    break;
                            }
                            break;
                        case 'SyncSwap':
                            acc['syncswap'] += inflowUsdc - outflowUsdc;
                            break;
                        case 'Meow':
                            acc['meow'] += inflowUsdc - outflowUsdc;
                            break;
                    }
                    return acc;
                },
                {
                    koi: 0,
                    clave: 0,
                    syncswap: 0,
                    zerolend_usdc: 0,
                    zerolend_tether: 0,
                    zerolend_dai: 0,
                    zerolend_other: 0,
                    meow: 0,
                } as Record<Key, number>,
            );

            monthlyData.koi = protocolToInvested['koi'];
            monthlyData.clave = protocolToInvested['clave'];
            monthlyData.syncswap = protocolToInvested['syncswap'];
            monthlyData.zerolend_usdc = protocolToInvested['zerolend_usdc'];
            monthlyData.zerolend_tether = protocolToInvested['zerolend_tether'];
            monthlyData.zerolend_dai = protocolToInvested['zerolend_dai'];
            monthlyData.zerolend_other = protocolToInvested['zerolend_other'];
            monthlyData.meow = protocolToInvested['meow'];
            monthly.push(monthlyData);
        });

        return {
            [ChartInterval.Daily]: daily,
            [ChartInterval.Weekly]: weekly,
            [ChartInterval.Monthly]: monthly,
            [ChartInterval.Cumulative]: cumulative,
        };
    }, [data, tokenPrices, dateRange]);

    const barProps: BarChartProps<Key> = {
        keys: [
            'koi',
            'clave',
            'syncswap',
            'zerolend_dai',
            'zerolend_usdc',
            'zerolend_tether',
            'zerolend_other',
            'meow',
        ],
        indexBy: 'date',
        axisBottomLegend: 'Date',
        axisLeftLegend: 'USD',
        enableTotals: true,
        valueFormat: ' >-$.0f',
        groupMode: 'stacked' as const,
        logScale: {
            type: 'symlog',
            constant: scale,
        },
        legends: [
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1,
                        },
                    },
                ],
            },
        ],
    };

    if (isLoading) {
        return (
            <Spin tip="Loading" size="small">
                <div className="p-12 bg-gray-100 rounded-sm" />
            </Spin>
        );
    }

    return (
        <div className="w-[100%] min-h-[40vh] max-h-[100vh]">
            {!isPreview && (
                <>
                    <Row>
                        <Col span={7}>
                            <Segmented
                                options={[
                                    ChartInterval.Daily,
                                    ChartInterval.Weekly,
                                    ChartInterval.Monthly,
                                    ChartInterval.Cumulative,
                                ]}
                                value={chartInterval}
                                defaultValue={defaultChartInterval}
                                onChange={(value): void => {
                                    if (
                                        value === ChartInterval.Daily &&
                                        dateRange[0] === 0
                                    ) {
                                        setDateRange([
                                            dayjs().subtract(1, 'week').unix() -
                                                80000,
                                            dayjs().unix(),
                                        ]);
                                    }
                                    setChartInterval(value as ChartInterval);
                                }}
                            />
                        </Col>
                        <Col span={7}>
                            <RangePicker
                                onChange={onDateChange}
                                value={
                                    dateRange[0] === 0
                                        ? undefined
                                        : [
                                              dayjs.unix(dateRange[0]),
                                              dayjs.unix(dateRange[1]),
                                          ]
                                }
                                minDate={dayjs('2024-01-21', dateFormat)}
                                maxDate={dayjs()}
                            />
                        </Col>
                        <Col span={4}>
                            <Slider
                                min={1000}
                                max={100000}
                                onChange={onChange}
                                value={typeof scale === 'number' ? scale : 1}
                            />
                        </Col>
                        <Col span={2}>
                            <InputNumber
                                min={1}
                                max={1000000}
                                style={{ margin: '0 16px' }}
                                value={typeof scale === 'number' ? scale : 1}
                                onChange={onChange}
                            />
                        </Col>
                    </Row>
                </>
            )}
            <BarChart data={chartData[chartInterval]} props={barProps} />
        </div>
    );
};
